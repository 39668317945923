/**
 *
 * @name 批量录题模式
 * @author 冷洪林
 * @date 2024-08-21 22:15
 *
 */
import './batch.css';
import {IP} from './App';
import axios from "axios";
import {Button, message} from "antd";
import React, {useEffect, useState} from "react";

const XLSX = require('xlsx');

const Batch = (props) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const insertData = () => {
    if (!data) {
      alert('请选择文件');
    }
    setLoading(true);
    // 上传接口
    let url = `http://${IP}:3007/api/setBatchAnswers`;
    axios.post(url, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then(response => {
        console.log(response);
        if (response?.data?.status === 0) {
          message.success('题目录入成功');
          setData(null);
        } else {
          message.error('题目录入失败');
        }
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        message.error('题目录入失败');
        setLoading(false);
      });
  };

  useEffect(() => {
    // 读取Excel文件
    const readExcel = (file) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, {type: 'array'});
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {header: 1});
        console.log('jsonData: ', jsonData);
        setData(jsonData)
      };
      reader.readAsArrayBuffer(file);
    };

    // 假设你有一个input元素来上传文件
    const inputElement = document.getElementById('input-excel');
    inputElement.addEventListener('change', (e) => {
      const file = e.target.files[0];
      readExcel(file);
    });
  }, []);

  return (
    <div className="App">
      <Button onClick={() => props.setMode('single')} type="primary"
              style={{width: 200, marginTop: 20}}>切换单题模式</Button>

      <input type="file" accept=".xls" id="input-excel" style={{width: 200, marginTop: 20}}/>

      <Button loading={loading} disabled={!data} onClick={() => insertData()} type="primary"
              style={{width: 200, marginTop: 20, marginBottom: 20}}>上传</Button>

      {data && <div>
        {Object.keys(data).map((key) => (
          <div key={key}>
            <div className="dataTitle">
              题目: {data[key][0]}
            </div>
            <div className={'bottom'}>
              【答案】: {data[key][1]}
            </div>
            <div className={'bottom'}>
              【正确答案】: {data[key][2]}
            </div>
            <div className={'bottom'}>
              【类型】: {data[key][3]}
            </div>
          </div>
        ))}
      </div>}
    </div>
  )
};

export default Batch;
