import './App.css';
import React, {useState} from 'react';
import axios from 'axios';
import {Input, Select, Button, message, Space} from 'antd';
import Batch from './batch';

const {TextArea} = Input;
const OPTIONS = [
  {label: 'A', value: '0'},
  {label: 'B', value: '1'},
  {label: 'C', value: '2'},
  {label: 'D', value: '3'}
];

const TYPES = [
  {
    value: '1',
    label: '1',
  },
  {
    value: '2',
    label: '2',
  },
  {
    value: '3',
    label: '3',
  },
  {
    value: '4',
    label: '4',
  },
  {
    value: '5',
    label: '5',
  },
];

// export const IP = '127.0.0.1';
export const IP = '39.104.202.137';
const App = () => {
    // 录题模式: single 单题模式   batch 批量模式
    const [mode, setMode] = useState('batch');

    // 动态码
    const [dynamicCode, setDynamicCode] = useState('');
    // 标题
    const [title, setTitle] = useState('');
    // 答案列表
    const [answers, setAnswers] = useState([]);
    // 正确答案
    const [rightAnswer, setRightAnswer] = useState('0');
    // 类型
    const [type, setType] = useState('');
    // 难度
    const [level, setLevel] = useState('1');

    // 是否正在加载
    const [loading, setLoading] = useState(false);

    // 答案输入框（4个）
    const [answerA, setAnswerA] = useState('');
    const [answerB, setAnswerB] = useState('');
    const [answerC, setAnswerC] = useState('');
    const [answerD, setAnswerD] = useState('');


    const onTitleChange = (e) => {
      console.log(e.target.value);
      setTitle(e.target.value);
    };

    const onAnswerChange = (e, index) => {
      let _ = answers;
      _[index] = e.target.value;
      setAnswers(_);
      console.log(_);

      if (index === 0) {
        setAnswerA(e.target.value);
      }
      if (index === 1) {
        setAnswerB(e.target.value);
      }
      if (index === 2) {
        setAnswerC(e.target.value);
      }
      if (index === 3) {
        setAnswerD(e.target.value);
      }
    };

    const handleRightAnswerChange = (value) => {
      console.log(`selected ${value}`);
      setRightAnswer(value)
    };

    const onTypeChange = (e) => {
      setType(e.target.value);
    };

    const handleLevelChange = (value) => {
      console.log(`selected ${value}`);
      setLevel(value);
    };

    const onSubmit = () => {
      if (!title) {
        alert('请输入题目');
        return
      }

      if (!(answers[0] && answers[1] && answers[2] && answers[3])) {
        alert('请完善答案');
        return
      }

      if (!type) {
        alert('请输入类型');
        return
      }

      console.log('提交', {title, answers, rightAnswer, type, level});
      setLoading(true);
      let data = {
        title,
        answers: answers.join(','),
        right_key: rightAnswer,
        difficulty: level,
        type_text: type
      };
      console.log('提交接口的参数', data);
      let url = `http://${IP}:3007/api/setAnswer`;
      axios.post(url, data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
        .then(response => {
          console.log(response);
          setLoading(false);
          setTitle('');
          setAnswers([]);
          setRightAnswer('0');
          setType('');
          setLevel('1');

          setAnswerA('');
          setAnswerB('');
          setAnswerC('');
          setAnswerD('');
          message.success('题目录入成功');
        })
        .catch(error => {
          console.log(error);
          setLoading(false);
          message.error('题目录入失败');
        });
    };

    if (dynamicCode !== '414620') {
      return (
        <div className="App">
          <Input value={dynamicCode} addonBefore="动态码" style={{width: 200, marginTop: 100}} placeholder="请输入动态码"
                 onChange={(e) => setDynamicCode(e.target.value)}/>
        </div>
      )
    }

    // 单题模式
    if (mode === 'single') {
      return (
        <div className="App">
          <Button onClick={() => setMode('batch')} type="primary"
                  style={{width: 200, marginTop: 20}}>切换批量模式</Button>
          <span className={"title"}>题目</span>
          <TextArea value={title} rows={4} className="title-input" placeholder="请输入题目" onChange={onTitleChange}/>
          <span className={"title"}>答案(4个)</span>
          <Input value={answerA} addonBefore="A" style={{width: 500, marginTop: 10}} placeholder="请输入答案"
                 onChange={(e) => onAnswerChange(e, 0)}/>
          <Input value={answerB} addonBefore="B" style={{width: 500, marginTop: 10}} placeholder="请输入答案"
                 onChange={(e) => onAnswerChange(e, 1)}/>
          <Input value={answerC} addonBefore="C" style={{width: 500, marginTop: 10}} placeholder="请输入答案"
                 onChange={(e) => onAnswerChange(e, 2)}/>
          <Input value={answerD} addonBefore="D" style={{width: 500, marginTop: 10}} placeholder="请输入答案"
                 onChange={(e) => onAnswerChange(e, 3)}/>
          <span className={"title"}>正确答案(1个)</span>
          <Select
            defaultValue={rightAnswer}
            value={rightAnswer}
            style={{
              width: 120,
            }}
            onChange={handleRightAnswerChange}
            options={OPTIONS}
          />

          <span className={"title"}>类型（历史、文化、数学...）</span>
          <Input value={type} onChange={onTypeChange} style={{width: 120}} placeholder="请输入类型"/>
          <span className={"title"}>难度（1-5）</span>
          <Select
            value={level}
            defaultValue={level}
            style={{
              width: 120,
            }}
            onChange={handleLevelChange}
            options={TYPES}
          />
          <Button onClick={onSubmit} type="primary" loading={loading} style={{width: 200, marginTop: 20}}>提交</Button>
        </div>
      )
    }

    // 批量模式
    if (mode === 'batch') {
      return <Batch setMode={setMode}/>
    }


  }
;

export default App;
